/** Here to Work: Pond **/

import React from "react";
import { preloadImages, showText, hideText, positionText, resetAnimationDelay } from "../../helpers"
import PrevNext from "../../components/navigation/prevnext"
import SEO from "../../components/seo"
import gsap from "gsap";

let globalState, data, preloadData;

class HereToWorkPage2 extends React.Component {

    constructor(props) {
        super(props);

        //update global state and assets data
        globalState = this.props.location.state;
        data = globalState.assets[2];
        preloadData = globalState.assets[3];

        //refs
        this.fullscreenBg = null;
    }

    state = {
        textVisible: false,
    }




    componentDidMount = () => {

        console.log('page 5 mounted');

        //position text
        positionText(this.refs.text1Ref, "left", "top", globalState.textPosition.leftTop);
        positionText(this.refs.text2Ref, "right", "top", globalState.textPosition.rightTop);


        //show text
        gsap.to(this.refs.text1Ref, 0, { autoAlpha: 1.0 });
        gsap.to(this.refs.text2Ref, 0, { autoAlpha: 1.0 });

        //add base track
        globalState.baseAudioRef.updateTrack(1, globalState.cdnUrl + data.soundfile1); //wind
        
        //remove prev track
        //globalState.baseAudioRef.updateTrack(2, false);

        //Preload assets from next page
        preloadImages([
            globalState.cdnUrl + preloadData.text1, 
            globalState.cdnUrl + preloadData.background,
            globalState.cdnUrl + preloadData.diggingSteam,
            globalState.cdnUrl + preloadData.richardCloseup
        ], this.preloadCallback);

    }

    preloadCallback = () => {
        this.refs.prevNextRef.showPrevNextBtns(globalState.showPrevNextDelay);
    }



    componentWillUnmount() {
        //Kill any tweens
        gsap.killTweensOf(this.refs.text1Ref);
        gsap.killTweensOf(this.refs.text2Ref);
    }


    toggleTextDisplay = (event) => {

        //toggle text visibility
        this.setState({ textVisible: !this.state.textVisible });

        //reset the animation delay
        resetAnimationDelay();

        if (this.state.textVisible) {
            //show text
            showText(this.refs.text1Ref, "left", "top", globalState.textPosition.leftTop);
            showText(this.refs.text2Ref, "right", "top", globalState.textPosition.rightTop);


        } else {
            //hide text
            hideText(this.refs.text1Ref, "left");
            hideText(this.refs.text2Ref, "right");
        }
    }

    isTransitioning = () => {

        //pause animations
    }


    render() {

        return (

            <>

                <link rel="prefetch" href="/here-to-work/3" />

                <PrevNext globalState={globalState} ref="prevNextRef"
                    nextLocation="/here-to-work/3" prevLocation="/here-to-work/1"
                    isTransitioning={this.isTransitioning} />

                {data && (
                <div className="fullpage-wrapper" style={{ backgroundImage: `url(${globalState.cdnUrl + data.background})`, backgroundSize: "cover", backgroundPosition: "top center" }}>
                    <SEO title="Pine Point - Here To Work" />

                    <div className="text-wrapper">
                        <div className="text text--left-top" ref="text1Ref">
                            <input type="image" src={globalState.cdnUrl + data.text1} style={{ marginLeft: "2vw", marginTop: "0" }} onClick={this.toggleTextDisplay} alt="Just outside of town, you’ll find the former tailings ponds, a treeless gravel blanket that smothers colour to the horizon." />
                        </div>
                        <div className="text text--right-top" ref="text2Ref">
                            <input type="image" src={globalState.cdnUrl + data.text2} style={{ marginRight: "2vw", marginTop: "0" }} onClick={this.toggleTextDisplay} alt="If all this manipulated geography had a footnote, it would read something like, ‘They came here to work.’" />
                        </div>
                    </div>

                </div>
                )}
            </>
        );
    }


};

export default HereToWorkPage2
